@use "sass:math";

@import "core/core";
@import "blocks/hero";
@import "blocks/motto";
@import "blocks/posterInfo";
@import "blocks/projects";
@import "blocks/author";
@import "blocks/recommendations";
@import "blocks/infoBox";
@import "blocks/footer";


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
